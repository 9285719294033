import React, { useCallback, useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Analytics } from "@vercel/analytics/react";
import { Canvg } from "canvg";
import ReactModal from "react-modal";
import SVG_DATA from "./components/SVGImports";

ReactModal.setAppElement("#root");

const ANIMATION_DURATION = 0.5;
const COLORS = [
  "#FF4136", // Bright red.
  "#0074D9", // Bright blue.
  "#FFD700", // Gold yellow.
  "#2ECC71", // Slightly brighter green.
  "#FFA07A", // Light salmon.
  "#B10DC9", // Bright purple.
  "#40E0D0", // Turquoise.
  "#FF69B4", // Hot pink.
  "#3D9970", // Medium sea green.
  "#EE82EE", // Violet.
  "#7B68EE", // Medium slate blue.
  "#FF8C00", // Dark orange.
  "#333333", // Black.
  "#D2691E", // Chocolate brown.
  "#00CED1", // Dark turquoise.
  "#FF4500", // Orange-red.
  "#FF80AB", // Light pink.
  "#8A2BE2", // Blue violet.
  "#5F9EA0", // Cadet blue.
  "#9ACD32", // Yellow green.
  "#FFFFFF", // Yellow green.
];

const Title = () => (
  <div className="text-3xl pt-5 font-bold text-center font-gluten">
    Color Binder 📒
  </div>
);

const SVGDisplay = React.memo(
  ({ handlePathClick, svgRef, svg: SvgComponent }) => {
    return (
      <div
        className="w-full flex justify-center items-center overflow-hidden"
        onClick={handlePathClick}
        ref={svgRef}
      >
        <SvgComponent className="w-full h-auto" />
      </div>
    );
  },
);

const ColorSwatch = ({ color, onClick, isActive }) => (
  <button
    className={`w-9 h-9 mx-1 my-1 cursor-pointer rounded-full transition-ring-width duration-300 transform hover:scale-110  ${
      isActive ? "ring-2 ring-blue-950" : "ring-2 ring-slate-300"
    }`}
    style={{ backgroundColor: color }}
    onClick={() => onClick(color)}
  ></button>
);

const ColorPicker = ({ chosenColor, setChosenColor }) => {
  return (
    <div className="flex flex-wrap justify-center m-1 p-0.5 rounded-3xl bg-slate-100 shadow">
      <input
        type="color"
        value={chosenColor}
        onChange={(e) => setChosenColor(e.target.value)}
        style={{
          backgroundColor: chosenColor,
          width: "200px",
          height: "15px",
          border: "none",
          borderRadius: "20px", // Add this for rounding
          appearance: "none",
          outline: "none",
          cursor: "pointer",
          WebkitAppearance: "none", // For Chrome, Safari, and Opera
        }}
      />
      <style>{`
        input[type="color"]::-webkit-color-swatch-wrapper {
          border: none;
          padding: 0;
        }
        input[type="color"]::-webkit-color-swatch {
          border: none;
          border-radius: 10px;
        }
      `}</style>
    </div>
  );
};

function downloadImage(svgRef, format = "png") {
  const svgData = new XMLSerializer().serializeToString(svgRef.current);

  // Create a canvas to render the SVG
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = "2048";
  canvas.height = "2048";

  // Optimize the rendering context for better quality
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";

  // Use Canvg to render the SVG on the canvas
  const v = Canvg.fromString(ctx, svgData);
  v.render();

  // Convert canvas to PNG or JPEG
  const url =
    format === "png"
      ? canvas.toDataURL("image/png")
      : canvas.toDataURL("image/jpeg");

  // Start the download process
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}_${currentDate
    .getHours()
    .toString()
    .padStart(2, "0")}-${currentDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}-${currentDate.getSeconds().toString().padStart(2, "0")}`;
  const link = document.createElement("a");
  link.href = url;
  link.download = `color-binder_${formattedDate}.${format}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function applySavedColorsToSvg(svgElement, svgName) {
  const savedColorsKey = `${svgName}_colors`;
  const savedColors = JSON.parse(localStorage.getItem(savedColorsKey) || "{}");
  const allPaths = Array.from(svgElement.querySelectorAll("path"));
  Object.keys(savedColors).forEach((pathIndex) => {
    const path = allPaths[pathIndex];
    if (path && savedColors[pathIndex]) {
      gsap.to(path, {
        duration: ANIMATION_DURATION,
        fill: savedColors[pathIndex],
      });
    }
  });
}

function getSavedSVGName() {
  return localStorage.getItem("selectedSVGName") || SVG_DATA[0].name;
}

function SVGThumbnail({ svg, isSelected, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`flex flex-col items-center justify-center w-56 h-56 rounded
            ${isSelected ? "bg-yellow-200 p-5" : "p-6"} 
            hover:bg-yellow-100 transition-all`}
    >
      {React.createElement(svg.component, {
        className: "svg-thumbnail",
      })}
    </button>
  );
}

function App() {
  const savedSVGName = getSavedSVGName();
  const initialSVG =
    SVG_DATA.find((svg) => svg.name === savedSVGName) || SVG_DATA[0];
  const svgRef = useRef(null);

  const [chosenColor, setChosenColor] = useState("#FFFFFF");
  const [showAttribution, setShowAttribution] = useState(false);
  const [selectedSVG, setSelectedSvg] = useState(initialSVG.component);
  const [search, setSearch] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const filteredSvgList = SVG_DATA.filter((svg) => {
    const searchTerm = search.toLowerCase();

    const categoryMatch = svg.categories.some((category) =>
      category.toLowerCase().includes(searchTerm),
    );

    return categoryMatch;
  });

  const handleSvgSelect = (svgName) => {
    const selectedSVGObj = SVG_DATA.find((svg) => svg.name === svgName);
    if (selectedSVGObj) {
      setSelectedSvg(selectedSVGObj.component);
      localStorage.setItem("selectedSVGName", svgName);
      setModalIsOpen(false);
    }
  };

  useEffect(() => {
    if (!svgRef.current) {
      return;
    }

    const savedSVGName = getSavedSVGName();
    applySavedColorsToSvg(svgRef.current, savedSVGName);
  }, [selectedSVG]);

  function printSVG(svgRef) {
    const svgData = new XMLSerializer().serializeToString(svgRef.current);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const v = Canvg.fromString(ctx, svgData);

    canvas.width = "2048";
    canvas.height = "2048";

    v.render();

    const imgURL = canvas.toDataURL("image/png");

    // Open a new window to print the image
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>Print Artwork</title></head><body>",
    );
    printWindow.document.write(
      '<img src="' + imgURL + '" style="width:100%;" />',
    );
    printWindow.document.write(
      '<div style="text-align:center; margin-top: 20px; font-size: 12px;">colorbinder.com 🥰</div>',
    );
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.onload = function () {
      printWindow.print();
    };
  }

  const InfoSVG = () => (
    <svg fill="#000000" viewBox="0 0 416.979 416.979" width="18" height="18">
      <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85 c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786 c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576 c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765 c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"></path>
    </svg>
  );

  const InfoButton = ({ onClick }) => (
    <button
      aria-label="Show SVG Attribution"
      className="mt-4 sm:mt-0 sm:ml-4 bg-slate-100 shadow w-14 h-14 rounded-md leading-none border-0 transition-colors duration-400 flex items-center justify-center hover:opacity-100 relative group"
      onClick={onClick}
    >
      <span className="absolute text-xs bg-black text-white p-1 rounded opacity-0 -top-8 transition-opacity duration-300 group-hover:opacity-100">
        Info
      </span>
      <InfoSVG />
    </button>
  );

  const handlePathClick = useCallback(
    (event) => {
      let path = event.target.closest("path"); // Find the closest path to the clicked element
      if (path) {
        gsap.to(path, { duration: ANIMATION_DURATION, fill: chosenColor });

        // Find the index of the path in the SVG
        const allPaths = Array.from(svgRef.current.querySelectorAll("path"));
        const pathIndex = allPaths.findIndex((p) => p === path);
        if (pathIndex === -1) return;
        const savedSVGName = getSavedSVGName();
        const savedColorsKey = `${savedSVGName}_colors`;
        const savedColors = JSON.parse(
          localStorage.getItem(savedColorsKey) || "{}",
        );
        savedColors[pathIndex] = chosenColor;
        localStorage.setItem(savedColorsKey, JSON.stringify(savedColors));
      }
    },
    [chosenColor],
  );

  return (
    <div className="flex flex-col justify-center items-center svg-container">
      {/* SVG Picker Overlay */}
      <button
        className="flex p-0 fixed top-4 left-4 bg-slate-100 shadow w-10 h-10 items-center justify-center rounded"
        onClick={() => setModalIsOpen(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <path d="M26,16c0,1.104-0.896,2-2,2H8c-1.104,0-2-0.896-2-2s0.896-2,2-2h16C25.104,14,26,14.896,26,16z" />
          <path d="M26,8c0,1.104-0.896,2-2,2H8c-1.104,0-2-0.896-2-2s0.896-2,2-2h16C25.104,6,26,6.896,26,8z" />
          <path d="M26,24c0,1.104-0.896,2-2,2H8c-1.104,0-2-0.896-2-2s0.896-2,2-2h16C25.104,22,26,22.896,26,24z" />
        </svg>
      </button>
      {modalIsOpen && (
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="SVG Picker"
          className="max-w-3xl w-full mx-auto p-5 bg-white rounded-lg shadow-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <div
            className="relative bg-slate-100 rounded-2xl"
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            {" "}
            <input
              type="text"
              placeholder="Find Coloring Pages..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="svg-search-input items-center block w-3/4 mx-auto mt-7 mb-6 p-2 rounded"
            />
            <button
              onClick={() => setModalIsOpen(false)}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition-colors duration-200"
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
              >
                <path
                  d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                  fill="#292D32"
                ></path>
              </svg>
            </button>
            {/* SVG Grid */}
            <div className="flex flex-wrap justify-center gap-2">
              {filteredSvgList.map((svg) => (
                <SVGThumbnail
                  svg={svg}
                  isSelected={selectedSVG === svg.component}
                  onClick={() => handleSvgSelect(svg.name)}
                />
              ))}
            </div>
          </div>
        </ReactModal>
      )}

      {/*Main Content */}

      <div className="flex flex-col items-center justify-center w-full">
        <Analytics />
        <Title className="mt-5" />
        <div className="w-full max-w-xl flex justify-center overflow-hidden">
          {selectedSVG && (
            <SVGDisplay
              handlePathClick={handlePathClick}
              svgRef={svgRef}
              svg={selectedSVG}
              classname="svg-main"
            />
          )}
        </div>

        <ColorPicker
          chosenColor={chosenColor}
          setChosenColor={setChosenColor}
        />

        <div className="flex flex-col sm:flex-row items-center justify-center mt-2 lg:ml-52">
          <div className="flex flex-wrap w-full sm:w-auto p-2 items-center justify-center rounded-3xl bg-slate-100 shadow">
            {COLORS.map((color) => (
              <ColorSwatch
                key={color}
                color={color}
                onClick={setChosenColor}
                isActive={color === chosenColor}
              />
            ))}
          </div>

          <div className="flex sm:w-auto justify-center space-x-3">
            <button
              id="download-button"
              aria-label="Download your artwork"
              className="mt-4 sm:mt-0 sm:ml-4 bg-slate-100 shadow w-14 h-14 rounded-md leading-none border-0 transition-colors duration-400 flex items-center justify-center hover:opacity-100 relative group"
              onClick={() => downloadImage(svgRef, "png")}
            >
              <span className="absolute text-xs bg-black text-white p-1 rounded opacity-0 -top-8 transition-opacity duration-300 group-hover:opacity-100">
                Download
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
              </svg>
            </button>

            <ReactModal
              isOpen={showAttribution}
              onRequestClose={() => setShowAttribution(false)}
              contentLabel="SVG Attribution"
              className="Modal relative p-5 bg-white rounded-lg shadow-lg flex flex-col items-start w-11/12 md:w-2/3 lg:w-1/2 mx-auto"
              overlayClassName="Overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
              <button
                onClick={() => setShowAttribution(false)}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition-colors duration-200"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                >
                  <path
                    d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                    fill="#292D32"
                  ></path>
                </svg>
              </button>
              <h2 className="text-xl mb-4">Creative Attribution</h2>
              <p>
                Image by{" "}
                <a
                  href="https://www.freepik.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Freepik
                </a>
              </p>
              <hr className="my-4 w-full" />
              <p className="text-gray-600">
                ColorBinder is a free, ads-free digital coloring book for
                everyone. We believe in providing a serene space for creativity
                without distractions. Enjoy your time here!
              </p>
              <footer className="text-center mt-3 mb-2 text-gray-500 text-xs">
                <p>© 2023 Cube Digital Technologies</p>
              </footer>
            </ReactModal>
            <button
              id="print-button"
              aria-label="Print your artwork"
              className="mt-4 sm:mt-0 sm:ml-4 bg-slate-100 shadow w-14 h-14 rounded-md leading-none border-0 transition-colors duration-400 flex items-center justify-center hover:opacity-100 relative group"
              onClick={() => printSVG(svgRef)}
            >
              <span className="absolute text-xs bg-black text-white p-1 rounded opacity-0 -top-8 transition-opacity duration-300 group-hover:opacity-100">
                Print
              </span>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
              >
                <path
                  d="M7 17H5C3.89543 17 3 16.1046 3 15V11C3 9.34315 4.34315 8 6 8H7M7 17V14H17V17M7 17V18C7 19.1046 7.89543 20 9 20H15C16.1046 20 17 19.1046 17 18V17M17 17H19C20.1046 17 21 16.1046 21 15V11C21 9.34315 19.6569 8 18 8H17M7 8V6C7 4.89543 7.89543 4 9 4H15C16.1046 4 17 4.89543 17 6V8M7 8H17M15 11H17"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
            <InfoButton onClick={() => setShowAttribution(true)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
