// Import SVGs
import { ReactComponent as SVG_01 } from "../assets/md/1.svg";
import { ReactComponent as SVG_02 } from "../assets/md/2.svg";
import { ReactComponent as SVG_03 } from "../assets/md/3.svg";
import { ReactComponent as SVG_04 } from "../assets/md/4.svg";
import { ReactComponent as SVG_05 } from "../assets/md/5.svg";
import { ReactComponent as SVG_06 } from "../assets/md/6.svg";
import { ReactComponent as SVG_07 } from "../assets/md/7.svg";
import { ReactComponent as SVG_08 } from "../assets/md/8.svg";
import { ReactComponent as SVG_09 } from "../assets/md/9.svg";
import { ReactComponent as SVG_10 } from "../assets/md/10.svg";
import { ReactComponent as SVG_11 } from "../assets/md/11.svg";
import { ReactComponent as SVG_12 } from "../assets/md/12.svg";
import { ReactComponent as SVG_13 } from "../assets/md/13.svg";
import { ReactComponent as SVG_14 } from "../assets/md/14.svg";
import { ReactComponent as SVG_15 } from "../assets/md/15.svg";
import { ReactComponent as SVG_16 } from "../assets/md/16.svg";
import { ReactComponent as SVG_17 } from "../assets/md/17.svg";
import { ReactComponent as SVG_18 } from "../assets/md/18.svg";
import { ReactComponent as SVG_19 } from "../assets/md/19.svg";
import { ReactComponent as SVG_20 } from "../assets/md/20.svg";
import { ReactComponent as SVG_21 } from "../assets/md/21.svg";
import { ReactComponent as SVG_22 } from "../assets/md/22.svg";
import { ReactComponent as SVG_23 } from "../assets/md/23.svg";
import { ReactComponent as SVG_24 } from "../assets/md/24.svg";
import { ReactComponent as SVG_25 } from "../assets/md/25.svg";

const SVG_DATA = [
  {
    component: SVG_25,
    name: "space_01",
    categories: ["astronaut", "space", "stars"],
  },
  {
    component: SVG_02,
    name: "food_01",
    categories: ["food", "drinks", "eating", "fruits", "sun"],
  },
  {
    component: SVG_01,
    name: "dragon_01",
    categories: ["Fantasy", "Animals", "Sun", "Nature"],
  },
  {
    component: SVG_03,
    name: "food_02",
    categories: ["food", "drinks", "eating", "fruits", "sun"],
  },
  {
    component: SVG_04,
    name: "flowers_01",
    categories: ["flowers", "nature", "insects"],
  },
  {
    component: SVG_05,
    name: "cats_01",
    categories: ["cats", "animals", "cute", "basket"],
  },
  {
    component: SVG_06,
    name: "bear_01",
    categories: ["animals", "bear", "nature", "honey", "sun"],
  },
  {
    component: SVG_07,
    name: "food_03",
    categories: ["food", "candy", "rainbow"],
  },
  {
    component: SVG_08,
    name: "unicorn_03",
    categories: ["unicorn", "animals", "rainbow", "clouds"],
  },
  {
    component: SVG_09,
    name: "cats_02",
    categories: ["cat", "cute"],
  },
  {
    component: SVG_10,
    name: "plant_01",
    categories: ["plant", "nature", "pot"],
  },
  {
    component: SVG_11,
    name: "cats_03",
    categories: ["cat", "plants", "food"],
  },
  {
    component: SVG_12,
    name: "sloth_01",
    categories: ["nature", "sloth", "snake", "forest", "trees"],
  },
  {
    component: SVG_13,
    name: "space_02",
    categories: ["space", "stars", "planets", "asteroid"],
  },
  {
    component: SVG_14,
    name: "flowers_02",
    categories: ["flowers", "bees", "garden", "nature", "rainbow"],
  },
  {
    component: SVG_15,
    name: "dinosaur_01",
    categories: ["animals", "dinosaur", "ancient"],
  },
  {
    component: SVG_16,
    name: "dogs_01",
    categories: ["cats", "dogs", "nature"],
  },
  {
    component: SVG_17,
    name: "bunny_01",
    categories: ["animals", "bunny", "deer", "bird", "forest", "tree"],
  },
  {
    component: SVG_18,
    name: "cats_03",
    categories: ["cats", "drinks", "clouds", "animals"],
  },
  {
    component: SVG_19,
    name: "dinosaur_02",
    categories: ["dinosaur", "volcano", "nature"],
  },
  {
    component: SVG_20,
    name: "space_03",
    categories: ["space", "astronaut", "planets", "stars"],
  },
  {
    component: SVG_21,
    name: "space_04",
    categories: ["space", "astronaut", "planets", "stars", "rockets", "sun"],
  },
  {
    component: SVG_22,
    name: "mermaid_01",
    categories: ["mermaid", "fish", "sea", "bubbles"],
  },
  {
    component: SVG_23,
    name: "mermaid_02",
    categories: ["mermaid", "fish", "sea", "bubbles", "seahorse"],
  },
  {
    component: SVG_24,
    name: "bunny_02",
    categories: ["animals", "bunny", "forest", "plants", "insect"],
  },
];

export default SVG_DATA;
