import React, { useCallback, useRef, useState } from "react";
import { gsap } from "gsap";
import { ReactComponent as MySvg } from "./assets/working-spec-example.svg";
import ReactModal from "react-modal";
const parse = require("html-react-parser");

ReactModal.setAppElement("#root");

const ANIMATION_DURATION = 0.5;
const COLORS = ["#FF4136", "#0074D9", "#FFD700", "#2ECC71", "#FFFFFF"];

const Title = () => (
  <div className="text-3xl font-bold text-center font-gluten">
    Upload and Test SVG!
  </div>
);

const SVGDisplay = React.memo(({ handlePathClick, svgRef, svgContent }) => {
  // Render either the uploaded SVG content (as React elements) or the default SVG component
  return (
    <div
      className="w-full max-w-screen-lg flex justify-center overflow-hidden"
      onClick={handlePathClick}
      ref={svgRef}
    >
      {svgContent ? (
        <div className="w-full h-auto">
          {parse(svgContent)}{" "}
          {/* Parsing and rendering SVG string content as React elements */}
        </div>
      ) : (
        <MySvg className="w-full h-auto" />
      )}
    </div>
  );
});

const ColorSwatch = ({ color, onClick, isActive }) => (
  <button
    className={`w-9 h-9 mx-1 my-1 cursor-pointer rounded-full transition-ring-width duration-300 transform hover:scale-110  ${
      isActive ? "ring-2 ring-blue-950" : "ring-2 ring-slate-300"
    }`}
    style={{ backgroundColor: color }}
    onClick={() => onClick(color)}
  ></button>
);

const downloadFile = (filename) => {
  // Create a new anchor element (not attached to the document)
  const a = document.createElement("a");

  // Use the `href` attribute for the URL to the file
  a.href = `./assets/${filename}`;

  // Set the `download` attribute to suggest a filename, so the browser downloads rather than navigates
  a.download = filename; // Or give a specific filename if needed

  // Trigger the download by simulating click
  a.click();

  // Clean up by removing the element from the document (optional, but good practice)
  a.remove();
};

function Upload() {
  const [chosenColor, setChosenColor] = useState("#FFFFFF");
  const [uploadedSVG, setUploadedSVG] = useState(null);

  const svgRef = useRef(null);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/svg+xml") {
      const reader = new FileReader();
      reader.onload = function (e) {
        setUploadedSVG(e.target.result);
      };
      reader.readAsText(file);
    } else {
      console.error("Please upload a valid SVG file.");
    }
  };

  const shouldNotColor = (element) => {
    const stroke = (element.getAttribute("stroke") || "").toLowerCase().trim();

    // Example of an additional check: paths with a 'none' fill attribute shouldn't be colored
    const fill = (element.getAttribute("fill") || "").toLowerCase().trim();

    return stroke === "#000000" || fill === "none"; // Add other conditions as needed
  };

  const handlePathClick = useCallback(
    (event) => {
      let targetElement = event.target;

      // Modify the logic to ensure we're targeting the correct element. If the clicked element
      // is not a direct path, this will bubble up through the ancestors to find the first path.
      while (
        targetElement &&
        targetElement.tagName !== "path" &&
        targetElement !== svgRef.current
      ) {
        targetElement = targetElement.parentElement;
      }

      // If we found a path element, proceed to color it
      if (
        targetElement &&
        targetElement.tagName === "path" &&
        !shouldNotColor(targetElement)
      ) {
        gsap.to(targetElement, {
          duration: ANIMATION_DURATION,
          fill: chosenColor,
        });
      }
    },
    [chosenColor], // No changes needed to dependencies here
  );

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      {" "}
      {/* Added a background color for contrast */}
      <Title className="mb-8" />{" "}
      {/* Increased margin-bottom for aesthetic spacing */}
      <input
        type="file"
        accept=".svg"
        onChange={handleUpload}
        className="mb-5 justify-center items-center"
      />
      <div className="w-full max-w-lg mb-5">
        {" "}
        {/* Adjust max-width as per your design preference */}
        <SVGDisplay
          handlePathClick={handlePathClick}
          svgRef={svgRef}
          svgContent={uploadedSVG}
        />
      </div>
      <div className="flex items-center justify-center mb-2 space-x-3">
        {" "}
        {/* Added space between buttons */}
        {/*<button*/}
        {/*  onClick={applyRandomColor}*/}
        {/*  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"*/}
        {/*>*/}
        {/*  Random Color*/}
        {/*</button>*/}
        {/* You can add more buttons here, and they will be aligned in this flex container */}
      </div>
      <div className="flex flex-wrap justify-center space-x-3">
        {" "}
        {/* Color swatches wrapper */}
        {COLORS.map((color) => (
          <ColorSwatch
            key={color}
            color={color}
            onClick={setChosenColor}
            isActive={color === chosenColor}
          />
        ))}
      </div>
      <div className="flex justify-center mb-5 mt-3 space-x-3">
        <button
          onClick={() => downloadFile("working-spec-example.svg")}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 text-xs rounded" // Smaller padding and text
        >
          Download Reference SVG
        </button>
        <button
          onClick={() => downloadFile("working-spec-example.ai")}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 text-xs rounded" // Smaller padding and text
        >
          Download Reference AI
        </button>
      </div>
    </div>
  );
}

export default Upload;
